<template>
  <div>
    <base-section
      id="serviceprovidercost-account"
      space="16"
    >
      <v-container>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="6"
          >
            <base-info-card :title="$t('costSP.mainTitle')" />
          </v-col>
        </v-row>
        <v-card>
          <v-tabs>
            <v-tab
              id="costSP_PastMonthlyInvoiceOption"
            >
              {{ $t('costSP.pastMonthlyInvoiceOption') }}
            </v-tab>
            <v-divider
              class="mx-4"
              inset
              vertical
            />
            <v-tab
              id="costSP_MonthlyConsumptionOption"
            >
              {{ $t('costSP.MonthlyConsumptionOption') }}
            </v-tab>
            <v-tab-item>
              <v-data-table
                :headers="headers"
                :items="myMonthlyCost"
                sort-by="date"
                class="elevation-1"
                :search="search"
              >
                <template #top>
                  <v-toolbar
                    flat
                  >
                    <v-toolbar-title>{{ $t('costSP.pastMonthlyInvoiceOption_title') }}</v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    />
                    <v-spacer />
                    <v-text-field
                      v-model="search"
                      :label="$t('hero.search')"
                      class="mx-4"
                    />
                    <!---     Dialog Box to display the monthly cost  ---------------------------------------->
                    <v-dialog
                      v-model="dialog"
                      persistent
                      scrollable
                      max-width="800px"
                    >
                      <v-card>
                        <v-card-title>
                          <base-info-card :title="`${ editedItem.date } - Price ${ editedItem.price }€`" />
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                              >
                                <h2
                                  v-for="(itemCostDetail, index1) in editedItem.costDetail"
                                  :key="index1"
                                  class="font-weight-black"
                                >
                                  Service: {{ itemCostDetail.title }} <br>
                                  <h6
                                    class="font-weight-regular"
                                  >
                                    Type: {{ itemCostDetail.type }} <br>
                                    {{ (itemCostDetail.type !== 'hit') ? 'Duration (min): ' : 'Hit number: ' }} {{ itemCostDetail.value }} <br>
                                    Sub-price: {{ itemCostDetail.subprice }}€ HT <br>
                                    Period when the service is used: <br>
                                    <ul>
                                      <li
                                        v-for="(itemOnPeriod, index2) in itemCostDetail.onperiod"
                                        :key="index2"
                                      >
                                        {{ itemOnPeriod.start.substring(0, 16) }} => {{ itemOnPeriod.end.substring(0, 16) }}
                                      </li>
                                    </ul>
                                  </h6>
                                </h2>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer />
                          <kdw-btn
                            @click="close"
                          >
                            {{ $t('button.cancel') }}
                          </kdw-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template #item.actions="{ item }">
                  <!--
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-eye-outline
                  </v-icon>
                  -->
                  <v-icon
                    v-if="editedItem.invoicePDF !== ''"
                    small
                    @click="downloadMonthlyCost(item)"
                  >
                    mdi-file-pdf
                  </v-icon>
                </template>
                <template #no-data />
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <!-------- TAB MONTHLY CONSUMPTION   ----------------------------->
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <b>{{ $t('costSP.amountWallet') }} {{ walletMaxAmountConsumption }}€ HT</b>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <kdw-btn
                        small
                        @click="increaseWallet"
                      >
                        {{ $t('costSP.walletIncreaseBtn') }}
                      </kdw-btn>
                      <v-chip
                        v-if="walletMaxAmountConsumption < myCurrentMonthlyConsumptionAmount"
                        class="ma-2"
                        color="red"
                        text-color="white"
                        x-large
                      >
                        {{ $t('costSP.walletOver') }}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <br>
                  <v-simple-table>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t('costSP.costNature') }}
                          </th>
                          <th class="text-left">
                            {{ $t('costSP.hit') }}
                          </th>
                          <th class="text-left">
                            {{ $t('costSP.cost') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in myCurrentMonthlyConsumption"
                          :key="item.text"
                        >
                          <td>{{ $t(item.text) }}</td>
                          <td>{{ item.value }}</td>
                          <td>{{ item.cost }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <br>
                  <p>{{ $t('costSP.total') }} {{ myCurrentMonthlyConsumptionAmount }}€ HT</p> <br>
                  <p>{{ (subscription === 'monthly') ? $t('costSP.monthlyFee') : $t('costSP.yearlyFee') }} {{ myCurrentMonthlyBasicCost }}€ HT</p> <br>
                  <p>{{ $t('costSP.freeTrial') }} {{ parameters.subscription.freeperiod }} {{ $t('costSP.freeTrial_Ending') }} {{ freeTrialStatus }}</p><br>
                  <p><b>{{ $t('costSP.totalBill') }} {{ myCurrentMonthlyConsumptionAmountInvoice }}€ HT</b></p><br>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator'
  import { fetchMonthCostSP, fetchSPCurrentMonthConsumption, fetchIsSPFreeTrial, COST_BASIC_FREE_MONTH_PERIOD, COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH, COST_BASIC_USAGE_MONTHLY_FEE_PER_YEAR, increaseWalletSize, computeMonthlySubscriptionCost } from '@/services/Cost'
  import { Logger, Storage } from 'aws-amplify'

  import { downloadBlob } from '@/helpers/GenericFunctionsS3Download'
  import { dateDiff, lastDayCurrentMonth } from '@/helpers/GenericFunctions'
  import { loadServiceProviderParameters } from '@/services/Parameters'

  const logger = new Logger('ServiceProviderCostAccount')

  @Component({ name: 'ServiceProviderCostAccount' })
  class ServiceProviderCostAccount extends Vue {
    myMonthlyCost = []
    myCurrentMonthlyConsumption = []
    myCurrentMonthlyConsumptionAmount = 0
    myCurrentMonthlyBasicCost = 0
    myCurrentMonthlyConsumptionAmountInvoice = 0
    freeTrialStatus = this.$i18n.t('costSP.freeTrial_Ended')
    walletMaxAmountConsumption = 0
    subscription = 'monthly'
    parameters = { searchRadius: { radius: 10 }, subscription: { type: 'KidiwiBasic', method: 'yearly', date: '1900-01-01T00:00:00.000Z', freeperiod: 1 }, wallet: { nextMonthValue: 30 }, legal: { default: '' }, language: { default: 'fr' } }

    search = ''
    dialog = false
    headers = [
      {
        text: this.$i18n.t('costSP.pastMonthlyInvoiceOption_date'),
        align: 'start',
        sortable: false,
        value: 'date',
      },
      { text: this.$i18n.t('hero.priceTable'), value: 'price' },
      // { text: 'invoice', value: 'invoicePDF' },
      { text: this.$i18n.t('hero.actionTable'), value: 'actions', sortable: false },
    ]

    editedIndex = -1
    editedItem = {
      date: '',
      price: 0,
      costDetail: {},
    }

    defaultItem = {
      date: '',
      price: 0,
      costDetail: {},
    }

    @Watch('dialog')
    dialogD (val) {
      val || this.close()
    }

    created () {
      // Here we computer the monthly cost depending on the subscription (monthly or yearly)
      loadServiceProviderParameters()
        .then(result => {
          if (result !== false) {
            // console.log('--------- result:', result)
            const billingMonth = new Date().toISOString().substr(0, 7) + '-' + lastDayCurrentMonth()
            this.parameters = JSON.parse(result.parameters)
            const monthlyResult = computeMonthlySubscriptionCost(result.parameters, billingMonth, result.createdAt)
            this.myCurrentMonthlyBasicCost = monthlyResult.value
            this.subscription = monthlyResult.subscriptionType
          }
        })
        .catch(err => {
          this.myCurrentMonthlyBasicCost = COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH
          logger.error(err)
        })
      // We are fetching the table of the first tab
      fetchMonthCostSP()
        .then(result => {
          this.myMonthlyCost = result
        })
        .catch(err => logger.error('error !! ' + err))

      // Here we compute the global amount depending on the free period trial
      fetchSPCurrentMonthConsumption(this.myCurrentMonthlyConsumption)
        .then(result => {
          this.myCurrentMonthlyConsumptionAmount = result
          fetchIsSPFreeTrial()
            .then(result => {
              this.walletMaxAmountConsumption = result.wallet
              if (result.months < COST_BASIC_FREE_MONTH_PERIOD) {
                // The trial is on going
                this.freeTrialStatus = this.$i18n.t('costSP.freeTrial_OnGoing')
                this.myCurrentMonthlyConsumptionAmountInvoice = 0
              } else {
                // The trial is finished
                this.freeTrialStatus = this.$i18n.t('costSP.freeTrial_Ended')
                if (this.myCurrentMonthlyConsumptionAmount > this.walletMaxAmountConsumption) {
                  this.myCurrentMonthlyConsumptionAmountInvoice = parseFloat(this.walletMaxAmountConsumption) + parseFloat(this.myCurrentMonthlyBasicCost)
                } else {
                  this.myCurrentMonthlyConsumptionAmountInvoice = parseFloat(this.myCurrentMonthlyConsumptionAmount) + parseFloat(this.myCurrentMonthlyBasicCost)
                }
              }
              // console.log('everything is fetched')
            })
        })
        .catch(err => logger.error(err))
    }

    editItem (item) {
      this.editedIndex = this.myMonthlyCost.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    }

    downloadMonthlyCost (item) {
      // console.log('item')
      // console.log(item)
      if ((item.invoice !== undefined) && (item.invoice !== null) && (item.invoice !== '')) {
        Storage.get(item.invoice, { download: true, level: 'public' })
          .then(result => {
            downloadBlob(result.Body, item.invoice)
          })
          .catch(err => logger.error(err))
      }
    }

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    }

    increaseWallet () {
      increaseWalletSize(this.walletMaxAmountConsumption)
        .then(result => {
          if (result === true) {
            this.walletMaxAmountConsumption = this.walletMaxAmountConsumption + 1
          }
        })
    }
  }

  export default ServiceProviderCostAccount
</script>
